// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-author-gatsby-js": () => import("/opt/build/repo/src/templates/author/gatsby.js" /* webpackChunkName: "component---src-templates-author-gatsby-js" */),
  "component---src-templates-editorial-gatsby-js": () => import("/opt/build/repo/src/templates/editorial/gatsby.js" /* webpackChunkName: "component---src-templates-editorial-gatsby-js" */),
  "component---src-templates-about-gatsby-js": () => import("/opt/build/repo/src/templates/about/gatsby.js" /* webpackChunkName: "component---src-templates-about-gatsby-js" */),
  "component---src-templates-article-gatsby-js": () => import("/opt/build/repo/src/templates/article/gatsby.js" /* webpackChunkName: "component---src-templates-article-gatsby-js" */),
  "component---src-templates-tags-gatsby-js": () => import("/opt/build/repo/src/templates/tags/gatsby.js" /* webpackChunkName: "component---src-templates-tags-gatsby-js" */),
  "component---src-indexes-articles-js": () => import("/opt/build/repo/src/indexes/articles.js" /* webpackChunkName: "component---src-indexes-articles-js" */),
  "component---src-indexes-authors-js": () => import("/opt/build/repo/src/indexes/authors.js" /* webpackChunkName: "component---src-indexes-authors-js" */),
  "component---src-indexes-editorials-js": () => import("/opt/build/repo/src/indexes/editorials.js" /* webpackChunkName: "component---src-indexes-editorials-js" */),
  "component---src-indexes-tags-js": () => import("/opt/build/repo/src/indexes/tags.js" /* webpackChunkName: "component---src-indexes-tags-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

